import { Config as ConfigType } from './types';
import { user } from './edon/user';
import { expert } from './edon/expert';
import { client } from './edon/client';
import { company } from './edon/company';
import { mission } from './edon/mission';
import { invitation } from './edon/invitation';
import { candidate } from './s30/candidate';
import { candidacy } from './edon/candidacy';
import { proposal } from './edon/proposal';
import { quotation } from './edon/quotation';
import { charge } from './edon/charge';
import { milestone } from './edon/milestone';
import { contract } from './edon/contract';
import { contractMilestone } from './edon/contractMilestone';
import { invoice } from './edon/invoice';
import { recommendation } from './edon/recommendation';
import { legalInformation } from './edon/legalInformation';
import { cgu } from './edon/cgu';
import { match } from './edon/match';
import { document } from './edon/document';
import { subCategory } from './edon/subCategory';
import { documentFile } from './edon/documentFile';
import { documentsCompanies } from './edon/documentsCompanies';
import { tag } from './edon/tag';

export const config: ConfigType = {
  login: 'password',
  hash: '',
  locale: 'fr',
  name: 'edon',
  dashboard: true,
  theme: {
    primary: '#26B6E9',
    secondary: '#B2CA3D',
    info: '#fcecf4',
  },
  defaultCountry: 'fr',
  preferredCountries: ['fr'],
  resources: [
    expert,
    client,
    user,
    subCategory,
    company,
    mission,
    invitation,
    candidate,
    candidacy,
    proposal,
    quotation,
    charge,
    milestone,
    contract,
    contractMilestone,
    invoice,
    recommendation,
    legalInformation,
    document,
    documentFile,
    documentsCompanies,
    cgu,
    match,
    tag,
  ],
};

export default config;
